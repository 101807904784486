// Any global rule that can't live anywhere else goes here
.Mui-focusVisible {
	box-shadow: none !important;
}

.MuiButton-outlinedPrimary:disabled {
	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.26) !important;
	}
}

.dark-mode .MuiButton-outlinedPrimary:disabled {
	&:hover {
		border: 1px solid rgba(255, 255, 255, 0.3) !important;
	}
}

.MuiAutocomplete-option {
	display: block !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.MuiPhoneNumber-positionStart {
	pointer-events: none;
}

#client-snackbar {
	color: #fff;
}

body {
	padding: 0 !important;
}
